<template>
  <div class="diy1">
    <div class="diy2">
      <div class="diy3">请准确填写您的收货地址，以便于我们为您快速交付产品</div>
      <div class="diy4">
        <div
          v-for="(item, index) in addressList"
          :key="item.id + index"
          :class="isDefault == index ? 'diy8plus' : 'diy8'"
          @click="sizeAddress(item, index)"
        >
          <div class="hiddenImg"></div>
          <div class="diy9">{{ item.receiver }}</div>
          <div class="diy10">{{ item.mobile }}</div>
          <div class="diy11">
            {{
              provinceFilter(addressCity, item.province) +
              cityFilter(addressArea, item.city) +
              districtFilter(addressCount, item.district) +
              streetFilter(addressStreet, item.street) +
              item.detail
            }}
          </div>
          <el-button class="diy12" @click="editAddress(item)">编辑</el-button>
        </div>
        <div class="diy5" @click="addAddress">
          <div class="diy6"></div>
          <div class="diy7">新增收货地址</div>
        </div>
      </div>
      <div class="diy13"></div>
      <div class="diy14">
        <div class="diy15">
          我们的销售支持团队预计将于5个工作日内为您完成产品安装和部署，以便更好的服务您的企业
        </div>
        <div class="top-23">
          <el-button class="diy16-1" @click="handelClick">取消</el-button>
          <el-button class="diy16" @click="sureNext">确认</el-button>
        </div>
      </div>
    </div>
  </div>
  <div>
    <el-dialog v-model="open" :show-close="false" @close="clean" width="660">
      <template #header="{ close, titleId, titleClass }">
        <div class="my-header">
          <h4 :id="titleId" :class="titleClass" class="open1">{{ title }}</h4>
          <el-button @click="close" class="open2"> </el-button>
        </div>
      </template>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="1px"
        class="demo-ruleForm"
        status-icon
      >
        <el-form-item label=" " prop="receiver">
          <el-input
            class="open3"
            v-model="ruleForm.receiver"
            placeholder="姓名"
          />
        </el-form-item>
        <el-form-item label=" " prop="mobile">
          <el-input
            class="open4"
            v-model="ruleForm.mobile"
            placeholder="手机号"
          />
        </el-form-item>
        <el-form-item label=" " prop="address">
          <div class="m-4">
            <el-cascader
              placeholder="选择地址"
              v-model="ruleForm.address"
              :options="options"
              @change="chooseAddress"
              filterable
              clearable
            />
          </div>
        </el-form-item>
        <el-form-item label=" " prop="detail">
          <el-input
            type="textarea"
            :rows="4"
            class="open6"
            v-model="ruleForm.detail"
            placeholder="详细地址"
          />
        </el-form-item>
        <el-form-item style="margin-top: 60px">
          <el-button class="open7" @click="closeForm()"> 取消 </el-button>
          <el-button class="open8" @click="submitForm(ruleFormRef)"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script setup>
import { listAddress, addEditAddress, defaultAddress } from "@/api/order";
import { addSaveAddress } from "@/api/orderCenter/index";
import {
  ref,
  reactive,
  onMounted,
  onBeforeUnmount,
  defineProps,
  defineEmits,
} from "vue";
// import { useRoute, useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import Address from "@/utils/address.json";
const props = defineProps({
  amendValue: String,
});
onBeforeUnmount(() => {});

const options = [];
const title = ref();
const ruleFormRef = ref();
const ruleForm = reactive({
  receiver: "",
  mobile: "",
  address: [],
  detail: "",
  province: "",
  city: "",
  district: "",
  street: "",
});
const rules = reactive({
  receiver: [{ required: true, message: "请输入姓名", trigger: "blur" }],
  mobile: [
    { required: true, message: "请输入手机号", trigger: "blur" },
    {
      pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
      message: "请输入正确的手机号码",
      trigger: "blur",
    },
  ],
  address: [{ required: true, message: "请选择地址", trigger: "blur" }],
  detail: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
});

const clean = () => {
  ruleForm.mobile = null;
  ruleForm.receiver = null;
  ruleForm.detail = null;
  ruleForm.address = [];
  return;
  ruleFormRef.value?.resetFields();
};
// const router = useRouter();
const addressList = ref([]);
const addressCity = ref([]);
const addressArea = ref([]);
const addressCount = ref([]);
const addressStreet = ref([]);
const addressQuery = () => {
  Address.forEach((city) => {
    addressCity.value.push({
      code: city.code,
      name: city.name,
    });
    if (city.children) {
      city.children.forEach((area) => {
        addressArea.value.push({
          code: area.code,
          name: area.name,
        });
        if (area.children) {
          area.children.forEach((count) => {
            addressCount.value.push({
              code: count.code,
              name: count.name,
            });
            if (count.children) {
              count.children.forEach((street) => {
                addressStreet.value.push({
                  code: street.code,
                  name: street.name,
                });
              });
            }
          });
        }
      });
    }
  });
};
const addressDeafault = ref([]);
const sureNextText = ref({});
const isDefault = ref();
const sizeAddress = (val, index) => {
  console.log(val);
  // sureNextText.value = addressDeafault.value;
  // const isDefault = ref();
  isDefault.value = index;
  /*// 设置为默认地址
  defaultAddress(val.id).then((res) => {
    if (res.code == 200) {
      addressDeafault.value = val;
      // console.log(addressDeafault.value);
      getlistAddress();
    }
  });*/
};
const provinceFilter = (addressCity, value) => {
  let obj = value;
  const val = addressCity.find((k) => k.code == obj);
  if (val) {
    return val.name;
  }
};
const cityFilter = (addressArea, value) => {
  let obj = value;
  const val = addressArea.find((k) => k.code == obj);
  if (val) {
    return val.name;
  }
};
const districtFilter = (addressCount, value) => {
  let obj = value;
  const val = addressCount.find((k) => k.code == obj);
  if (val) {
    return val.name;
  }
};
const streetFilter = (addressStreet, value) => {
  let obj = value;
  const val = addressStreet.find((k) => k.code == obj);
  if (val) {
    return val.name;
  }
};
const getlistAddress = () => {
  listAddress().then((res) => {
    addressList.value = res.data;
    // console.log(addressList.value);
    res.data.forEach((el) => {
      if (el.isDefault == 1) {
        addressDeafault.value = el;
      }
    });
  });
};
const addressId = ref(null);
const submitForm = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      if (addressId.value != null) {
        ruleForm.province = ruleForm.address[0];
        ruleForm.city = ruleForm.address[1];
        ruleForm.district = ruleForm.address[2];
        ruleForm.street = ruleForm.address[3];
        ruleForm.id = addressId.value;
        delete ruleForm.address;
        addressId.value = null;
        addEditAddress(ruleForm).then((res) => {
          if (res.code == 200) {
            ElMessage({
              message: "修改地址成功",
              type: "success",
            });
            open.value = false;
            getlistAddress();
          } else {
            ElMessage({
              message: res.message,
              type: "error",
            });
          }
        });
      } else {
        ruleForm.province = ruleForm.address[0];
        ruleForm.city = ruleForm.address[1];
        ruleForm.district = ruleForm.address[2];
        ruleForm.street = ruleForm.address[3];
        delete ruleForm.id;
        delete ruleForm.address;
        addEditAddress(ruleForm).then((res) => {
          if (res.code == 200) {
            ElMessage({
              message: "新增地址成功",
              type: "success",
            });
            open.value = false;
            getlistAddress();
          } else {
            ElMessage({
              message: res.message,
              type: "error",
            });
          }
        });
      }
    } else {
      console.log("error submit!", fields);
    }
  });
};
const closeForm = () => {
  open.value = false;
};

const editAddress = (val) => {
  clean();
  title.value = "修改收货地址";
  ruleForm.mobile = val.mobile;
  ruleForm.receiver = val.receiver;
  ruleForm.detail = val.detail;
  ruleForm.address.push(val.province.toString());
  ruleForm.address.push(val.city.toString());
  ruleForm.address.push(val.district.toString());
  ruleForm.address.push(val.street.toString());
  addressId.value = val.id;
  open.value = true;
};
const addAddress = () => {
  clean();
  title.value = "新增收货地址";
  addressId.value = null;
  open.value = true;
};
// const route = useRoute();
const open = ref(false);
// 确定按钮
const emits = defineEmits();
const sureNext = async () => {
  console.log(sureNextText.value, "收货地址");
  if (addressDeafault.value.length != 0) {
    const data = reactive({
      mobile: addressDeafault.value.mobile,
      detail: addressDeafault.value.detail,
      receiver: addressDeafault.value.receiver,
      id: addressDeafault.value.id,
      orderNumber: props.amendValue,
      province: addressDeafault.value.province,
      city: addressDeafault.value.city,
      district: addressDeafault.value.district,
      street: addressDeafault.value.street,
    });
    console.log(data);
    await addSaveAddress(data);
    emits("sureNextChangeId", 1);
  } else {
    ElMessage({
      message: "请选择收货地址",
      type: "warning",
    });
  }
};
const handelClick = () => {
  emits("sureNextChangeId", 1);
};
onMounted(() => {
  getlistAddress();
  Address.forEach((item1, index1) => {
    options.push({
      value: item1.code,
      label: item1.name,
      children: [],
    });
    if (item1.children) {
      item1.children.forEach((item2, index2) => {
        if (item2.children) {
          options[index1].children.push({
            value: item2.code,
            label: item2.name,
            children: [],
          });
        } else {
          options[index1].children.push({
            value: item2.code,
            label: item2.name,
          });
        }
        if (item2.children) {
          item2.children.forEach((item3, index3) => {
            if (item3.children) {
              options[index1].children[index2].children.push({
                value: item3.code,
                label: item3.name,
                children: [],
              });
            } else {
              options[index1].children[index2].children.push({
                value: item3.code,
                label: item3.name,
              });
            }
            if (item3.children) {
              item3.children.forEach((item4) => {
                options[index1].children[index2].children[index3].children.push(
                  {
                    value: item4.code,
                    label: item4.name,
                  }
                );
              });
            }
          });
        }
      });
    }
  });
  addressQuery();
});
</script>
<style lang="less" scoped>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.el-row[data-v-2a2fdf7f] {
  margin-bottom: 8px !important;
}
.my-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f2f2f2;
  width: 660px;
  height: 60px;
  position: relative;
  left: -20px;
  top: -20px;
}
::v-deep .el-cascader .el-input .el-input__inner {
  text-overflow: ellipsis;
  cursor: pointer;
  height: 40px;
  width: 563px;
}
.open1 {
  width: 108px;
  height: 18px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin: 18px 0 0 20px;
}
.open2 {
  background: url("../../order/image/close.png") no-repeat;
  width: 14px;
  height: 14px;
  border: none;
  position: absolute;
  right: 5px;
  margin: 23px 8px 0 0;
}
.open2:hover {
  background: url("../../order/image/closeactive.png") no-repeat;
  width: 30px;
  height: 30px;
  border: none;
  position: absolute;
  right: 5px;
  margin: 15px 8px 0 0;
}
.open3 {
  height: 40px;
}
.open4 {
  height: 40px;
}
.open5 {
  height: 40px;
}
.open6 {
  height: 40px;
}

.open7 {
  width: 160px;
  height: 40px;
  background: #aaaaaa;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-left: 296px;
}
.open8 {
  width: 160px;
  height: 40px;
  background: #ce1200;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.diy1 {
  width: 990px;
  background-color: #f2f2f2;
  .diy2 {
    width: 990px;
    background: #ffffff;
    // margin: 48px auto 60px auto;

    .diy3 {
      width: 530px;
      height: 20px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      // padding: 64px 0 0 70px;
      margin-left: 66px;
    }
    .diy4 {
      width: 990px;
      margin-top: 30px;
      margin-left: 70px;
      display: flex;
      flex-wrap: wrap;
      .diy8 {
        width: 320px;
        height: 200px;
        border: 1px solid #d9d9d9;
        margin-right: 16px;
        margin-bottom: 16px;
        .diy9 {
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #111111;
          margin: 30px 0 0 31px;
        }
        .diy10 {
          height: 10px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin: 25px 0 0 31px;
        }
        .diy11 {
          width: 235px;
          height: 36px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin: 13px 0 0 31px;
        }
        .diy12 {
          width: 60px;
          height: 32px;
          background: #ce1200;
          border-radius: 5px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          margin: 28px 0 0 247px;
        }
      }
      .diy8plus {
        width: 320px;
        height: 200px;
        border: 1px solid #ce1200;
        margin-right: 16px;
        margin-bottom: 16px;
        .hiddenImg {
          background: url("../../order/image/check.png") no-repeat;
          width: 24px;
          height: 24px;
          float: right;
          margin: -10px;
        }
        .diy9 {
          height: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #111111;
          margin: 30px 0 0 31px;
        }
        .diy10 {
          height: 10px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin: 25px 0 0 31px;
        }
        .diy11 {
          width: 235px;
          height: 36px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin: 13px 0 0 31px;
        }
        .diy12 {
          width: 60px;
          height: 32px;
          background: #ce1200;
          border-radius: 5px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          margin: 28px 0 0 247px;
        }
      }
      .diy5 {
        width: 320px;
        height: 200px;
        border: 1px dashed transparent;
        background: linear-gradient(#fff, #fff) padding-box,
          repeating-linear-gradient(
            -45deg,
            #d9d9d9 0,
            #d9d9d9 0.4em,
            #fff 0,
            #fff 0.6em
          );
        .diy6 {
          background-image: url("../../order/image/add.png");
          background-repeat: no-repeat;
          background-position: center;
          width: 30px;
          height: 30px;
          margin: 73px 0 0 145px;
        }
        .diy7 {
          width: 79px;
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 12px 0 0 121px;
        }
      }
      .diy5:hover {
        width: 320px;
        height: 200px;
        border: 1px dashed transparent;
        background: linear-gradient(#fff, #fff) padding-box,
          repeating-linear-gradient(
            -45deg,
            #aaaaaa 0,
            #aaaaaa 0.4em,
            #fff 0,
            #fff 0.6em
          );
        .diy6 {
          background-image: url("../../order/image/addactive.png");
          background-repeat: no-repeat;
          background-position: center;
          width: 30px;
          height: 30px;
          margin: 73px 0 0 145px;
        }
        .diy7 {
          width: 79px;
          height: 13px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          margin: 12px 0 0 121px;
        }
      }
    }
    .diy13 {
      width: 1150px;
      height: 1px;
      background: #e1e1e1;
      margin-top: 39px;
      margin-left: 25px;
    }
    .diy14 {
      height: 69px;
      display: flex;
      justify-content: space-between;
      .diy15 {
        // width: 506px;
        height: 13px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #595959;
        margin: auto 0;
        margin-left: 70px;
      }
      .top-23 {
        margin: auto 0;
        margin-right: 80px;
        .diy16 {
          width: 100px;
          height: 48px;
          background: #ce1200;
          border-radius: 5px;
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
        .diy16-1 {
          width: 100px;
          height: 48px;
          background: #fff;
          border-radius: 5px;
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666;
        }
      }
    }
  }
}
:deep(.el-dialog__body) {
  padding: 0px 10px 10px 20px !important;
  color: var(--el-text-color-regular);
  font-size: var(--el-dialog-content-font-size);
}
:deep(.el-dialog__header) {
  padding: 20px 20px 0px 20px !important;
  color: var(--el-text-color-regular);
  font-size: var(--el-dialog-content-font-size);
}

::v-deep .el-statistic__content {
  font-weight: var(--el-statistic-content-font-weight);
  font-size: var(--el-statistic-content-font-size);
  color: #fff;
}
</style>
